import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "./context/context";
import Environment from "./context/environment/env";
import {
  UseSpinContract,
  UseVipTokenContract,
  VoidSigner,
} from "./context/hooks";
import { Header } from "./views/header";
import useSound from "use-sound";
import { Machine } from "./views/pages/soltmachine";
import win from "./assets/audio/win.wav";
import coin from "./assets/audio/coin.wav";
import fail from "./assets/audio/fail.wav";
import { Snackbars } from "./components/toast";
import Info from "./components/info";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { Selector } from "./components/selector";
import { ethers } from "ethers";
import tokenABI from "./context/environment/tokenAbi.json";
import Loading from "./loading";
import icon1 from "./assets/iocns/ic1.gif";
import icon3 from "./assets/iocns/ic11.gif";
import icon4 from "./assets/iocns/ic12.gif";
// import { Switchmodal } from "./model/switchmodal";
// import Web3 from "web3";
import { WalletDialog } from "./model";
import { AgeModal } from "./model/agemodal";
import { Box } from "@mui/system";
import { Allowed } from "./views/pages/allowed";
import { Button } from "@mui/material";
import { VipStatusModal } from "./model/vipmodal";
import WinnerTable from "./components/table";
import { _getWinners } from "./utils";
import { Addtoken } from "./components/addtoken";

const fruits = [icon1, icon3, icon4];
const App = () => {
  const [playing, setplaying] = React.useState(false);
  const [_backend, set_backend] = React.useState(false);
  const [winFruite, setwinFruite] = React.useState(null);
  const [selectToken, setSelectToken] = React.useState();
  const [isOpen, setisOpen] = React.useState(false);
  const [cost, setCost] = useState(0);
  const [_winersPeople, setWinnersPeople] = useState([]);
  const [_investors, _setInvestors] = useState(0);
  const [_jacpotsize, _setJackPotSize] = useState(0);
  const [counter, setCounter] = useState(1);
  const [loading, setloading] = React.useState(false);
  const [ageopen, ageSetOpen] = useState(true);
  const [_authAge, _setAuthAge] = React.useState(0);
  const [matchedCondition, setMatchedCondition] = useState(false);
  const [vipstatus, setVipStatus] = useState(false);
  const [_isVip, _setIsVip] = useState(false);
  const [_wins, setWin] = useState(0);
  const [lose, setLose] = useState(0);
  const { account, signer, chainId } = useContext(AppContext);
  const vipToken = UseVipTokenContract(signer);
  const contract = UseSpinContract(signer);

  const handleClick = async () => {
    if (!account) {
      toast.error("Please connect your wallet");
      return;
    }
    if (+cost < 0) {
      toast.error("Please enter a valid amount");
      return;
    }
    if (!selectToken) {
      toast.error("Please select token");
      return;
    }
    if (+counter <= 0) {
      toast.error("Please select your spin round");
      return;
    }
    try {
      setplaying(true);
      set_backend(false);
      setplaying(true);
      playActive();
      setMatchedCondition(false);
      let _contract;
      if (signer) {
        _contract = new ethers.Contract(selectToken, tokenABI, signer);
      } else {
        _contract = new ethers.Contract(selectToken, tokenABI, VoidSigner);
      }
      let allowanceToken = await _contract.allowance(
        account,
        Environment.SpinContractAddress
      );
      let supply = await _contract.totalSupply();
      let spincost = await contract.spinCost(selectToken);
      if (allowanceToken < +spincost * +counter) {
        const approveTx = await _contract.approve(
          Environment.SpinContractAddress,
          supply
        );
        await approveTx.wait();
      }
      const tx = await contract.Spin(selectToken, counter.toString());
      await tx.wait();
      const _desion = await contract.GetUserLastSpin(account);
      if (_desion === "HouseEdge") {
        setwinFruite(fruits[1]);
        set_backend(true);
        setplaying(false);
        playOn();

        toast.success("HouseEdge!");
        setMatchedCondition(false);
      } else if (_desion === "BigWin") {
        setwinFruite(fruits[2]);
        set_backend(true);
        setplaying(false);
        playOn();

        toast.success("BigWin!");
        setMatchedCondition(false);
      } else if (_desion === "StandardWin") {
        setwinFruite(fruits[0]);
        set_backend(true);
        setplaying(false);
        playOn();

        toast.success("StandardWin!");
        setMatchedCondition(false);
      } else {
        setplaying(false);
        setMatchedCondition(true);
        toast.error("You lost");

        playOff();
      }
      _init(contract);
      init2();
      init();
    } catch (error) {
      init2();
      init();
      if (error?.data?.message) {
        toast.error(error?.data?.message);
      } else {
        toast.error(error?.message);
      }
      setplaying(false);
      playOff();
      setMatchedCondition(true);
      // setMatchedCondition(false);
    }
  };

  const init = async () => {
    let getnewContract;
    if (signer) {
      getnewContract = new ethers.Contract(selectToken, tokenABI, signer);
    } else {
      getnewContract = new ethers.Contract(selectToken, tokenABI, VoidSigner);
    }
    const decimal = await getnewContract.decimals();
    if (selectToken) {
      const jackpotSize = await contract.BigWinReward(selectToken);
      _setJackPotSize(formatUnits(jackpotSize, decimal));
      const standardToken = await contract.StandardWinReward(selectToken);
      _setInvestors(formatUnits(standardToken, decimal));
    }
  };

  useEffect(() => {
    if (selectToken && contract) {
      init();
    }
  }, [selectToken, contract]);

  // React.useEffect(() => {
  //   if (window.ethereum) {
  //     let chain = async () => {
  //       const web3 = new Web3(Web3.givenProvider);
  //       const chainid = await web3.eth.getChainId();
  //       if (chainid !== 97 || chainid !== 80001) {
  //         setSwitchNetwork(true);
  //       } else {
  //         setSwitchNetwork(false);
  //       }
  //     };
  //     chain();
  //   }
  // }, []);

  const VipHandler = async () => {
    try {
      if (!account) {
        return toast.error("Please connect your wallet");
      }
      let isVip = await contract.GetUserVIP(account);
      if (isVip) {
        return toast.error("You are already a VIP");
      }

      let supply = await vipToken.totalSupply();
      const approveTx = await vipToken.approve(
        Environment.SpinContractAddress,
        supply
      );
      await approveTx.wait();
      const tx = await contract.VIPSERVICE();
      await tx.wait();
      toast.success("VIP Service activated");
      setVipStatus(true);
    } catch (error) {
      if (error?.data?.message) {
        setVipStatus(false);
        toast.error(error?.data?.message);
      } else {
        toast.error(error?.message);
      }
    }
  };

  const handleIncrement = () => {
    setCounter(counter + 1);
  };
  const handleDecrement = () => {
    if (counter > 1) {
      setCounter(counter - 1);
    }
  };

  useEffect(() => {
    let chain = async () => {
      let isVip = await contract.GetUserVIP(account);
      if (isVip) {
        _setIsVip(isVip);
      }

      if (+chainId !== 56) {
        setSwitchNetwork(true);
      } else {
        setSwitchNetwork(false);
      }
    };
    if (account) {
      chain();
    }
  }, [chainId]);

  React.useEffect(() => {
    if (contract) {
      _init(contract);
    }
  }, [contract]);

  const _init = async (contract) => {
    try {
      let _winspeople = await _getWinners(contract);
      if (_winspeople) {
        setWinnersPeople(_winspeople);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (account) {
        const _players = await contract.Player(account);

        setWin(_players.WinCount);
        setLose(_players.LoseCount);
      }
    })();
  }, [account]);

  const init2 = async () => {
    const _players = await contract.Player(account);
    setWin(_players.WinCount);
    setLose(_players.LoseCount);
  };

  React.useEffect(() => {
    if (account) {
      init2();
    }
  }, [account]);

  const [playActive] = useSound(coin, { volume: 0.25 });
  const [playOn] = useSound(win, { volume: 0.25 });
  const [playOff] = useSound(fail, { volume: 0.25 });
  const [_network, setSwitchNetwork] = useState(false);

  return (
    <div className="App">
      <VipStatusModal SetOpen={setVipStatus} open={vipstatus} />
      <AgeModal
        ageSetOpen={ageSetOpen}
        ageopen={ageopen}
        _setAuthAge={_setAuthAge}
      />
      <WalletDialog isSetOpen={setisOpen} isopen={isOpen} />
      <Loading loading={loading} />
      <Snackbars open={_network} setOpen={setSwitchNetwork} />
      <Header isSetOpen={setisOpen} _authAge={_authAge} />
      {+_authAge === 1 ? (
        <Box>
          <Selector setSelectToken={setSelectToken} setloading={setloading} />
          <Info
            standardwin={_investors}
            jackpost={_jacpotsize}
            // contract={contract}
            // account={account}
            _wins={_wins}
            lose={lose}
          />
          <Machine
            handleClick={handleClick}
            playing={playing}
            _backend={_backend}
            winFruite={winFruite}
            setMatchedCondition={setMatchedCondition}
            matchedCondition={matchedCondition}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            counter={counter}
          />
        </Box>
      ) : (
        ""
      )}
      {+_authAge === 2 ? <Allowed /> : ""}
      {/* <Switchmodal
        _switchSetOpen={setSwitchNetwork}
        _switchopen={_network}
        isSetOpen={setisOpen}
      /> */}

      <Box textAlign="center">
        {+_authAge === 1 ? (
          <Box>
            <Button
              disabled={_isVip}
              onClick={VipHandler}
              variant="contained"
              sx={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#D5339A",
                marginRight: "1rem",
                borderRadius: "5px",
                color: "#fff",
                fontSize: ["20px", "20px", "25px"],
                fontFamily: "'Bebas Neue', cursive",
                boxShadow:
                  "-5px 1px 0px 1px #D5339A, -2px 2px #D5339A, -2px 2px #D5339A",
                border: "1px solid #D5339A",
                textShadow: "black 1px 1px 2px",
                transition: "all 0.3s ease-in-out",
                outline: "none",
              }}
            >
              <span
                style={{
                  color: "#fff",
                }}
              >
                {_isVip ? "you Are Already VIP" : "become VIP"}
              </span>
            </Button>
          </Box>
        ) : (
          ""
        )}
      </Box>
      {+_authAge === 1 ? <WinnerTable _winersPeople={_winersPeople} /> : ""}
      {+_authAge === 1 ? <Addtoken /> : ""}
    </div>
  );
};

export default App;

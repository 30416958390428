import { Box } from "@mui/system";
import React from "react";

export const Allowed = () => {
  return (
    <Box mt={4}>
      <Box
        sx={{
          fontSize: [20, 30, 50],
          color: "red",
          fontFamily: "'Bebas Neue', cursive",
          textAlign: "center",
        }}
      >
        <Box>Oh no!</Box>
        <Box>You are not old enough to Play... Sorry!</Box>
      </Box>
    </Box>
  );
};

import React, { useContext, useState } from "react";
import { Container, Grid } from "@mui/material";

import { Box } from "@mui/system";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { AppContext } from "../context/context";
import {
  UseSpinContract,
  UseVipTokenContract,
  VoidSigner,
} from "../context/hooks";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import tokenABI from "../context/environment/tokenAbi.json";
import { parseUnits } from "@ethersproject/units";
import Environment from "../context/environment/env";
import { _getAllTokens } from "../utils";
// import { CONNECT_BUTTON_CLASSNAME } from "web3modal";

export const StyledButton = styled("button")(
  ({ theme }) => `
    font-family: Bebas Neue;
      font-size: 1.5rem;
      letter-spacing: 0.07em;
      box-sizing: border-box;
      width:100%;
      min-height: calc(1.5em + 22px);
      background: transparent;
      border: 1px solid #D5339A;
      border-radius: 0.75em;
      margin-top:7px;
      padding: 10px;
      text-align: left;
      line-height: 1.5;
      color: #D5339A;
      &:hover {
        cursor:pointer
      }
      &.${selectUnstyledClasses.expanded} {
        &::after {
          content: '▴';
        }
      }
      &::after {
        content: '▾';
        float: right;
      }
      `
);

export const StyledListbox = styled("ul")(
  ({ theme }) => `
      font-family: Bebas Neue;
      font-size: 1.5rem;
      letter-spacing: 0.05em;
      box-sizing: border-box;
      padding: 5px;
      margin: 10px 0;
      width:100%;
      background: #34C1C3;
      border: 1px solid #D5339A;
      border-radius: 0.75em;
      color: #D5339A;
      overflow: auto;
      outline: 0px;
      `
);
export const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    font-family: Bebas Neue;
    font-size: 1.5rem;
    letter-spacing: 0.05em;
      list-style: none;
      padding: 8px;
      border-radius: 0.45em;
      cursor: pointer;
      &:last-of-type {
        border-bottom: none;
      }
      &:hover:not(.${optionUnstyledClasses.disabled}) {
        background-color: #000000;
        color: #ffffff;
      }
      `
);
export const StyledPopper = styled(PopperUnstyled)`
  z-index: 10;
`;
export const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };
  return <SelectUnstyled {...props} ref={ref} components={components} />;
});

export const Selector = ({ setSelectToken, setloading }) => {
  const [tokenValue, setTokenValue] = useState("");
  const [tokenName, seTokenName] = useState("");
  const [tokenamount, setTokenAmount] = useState();
  const { account, signer } = useContext(AppContext);
  const contract = UseSpinContract(signer);
  const vipToken = UseVipTokenContract(signer);
  const [options, setOptions] = React.useState([]);
  const optionChangeHandler = async (option) => {
    setSelectToken(option);
  };

  // const _AddHandler = async (event) => {
  //   try {
  //     if (!account) {
  //       return toast.error("Please connect to Metamask");
  //     }
  //     if (!tokenamount) {
  //       return toast.error("Please enter amount");
  //     }
  //     if (!tokenValue) {
  //       return toast.error("Please select token");
  //     }
  //     const _token = tokenValue.trim();
  //     console.log(_token, "token");

  //     const _contract = new ethers.Contract(_token, tokenABI, signer);
  //     const name = await _contract.name();
  //     seTokenName(name);
  //     if (name) {
  //       let supply = await vipToken.totalSupply();
  //       let allowanceToken = await vipToken.allowance(
  //         account,
  //         Environment.SpinContractAddress
  //       );
  //       let spincost = await contract.spinCost(Environment.vipTokenAddress);
  //       if (allowanceToken < +spincost) {
  //         const approveTx = await vipToken.approve(
  //           Environment.SpinContractAddress,
  //           supply
  //         );
  //         await approveTx.wait();
  //       }
  //       const tx = await contract.addtoken(
  //         _token,
  //         parseUnits(tokenamount.toString())
  //       );
  //       await tx.wait();
  //       setTokenValue("");
  //       toast.success("Token added successfully");
  //       _getAllTokens();
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //     if (error?.data?.message) {
  //       toast.error(error?.data?.message);
  //     } else {
  //       toast.error(error?.message);
  //     }
  //   }
  // };

  // const _getAllTokens = async () => {
  //   try {
  //     setloading(true);
  //     const length = await contract.length();
  //     const getTokensList = Array.from(Array(+length).keys());
  //     if (getTokensList.length) {
  //       const tokens = await Promise.all(
  //         getTokensList.map(async (i) => {
  //           let _token = await contract.tokens(i);
  //           let _contract;
  //           if (signer) {
  //             _contract = new ethers.Contract(_token, tokenABI, signer);
  //           } else {
  //             _contract = new ethers.Contract(_token, tokenABI, VoidSigner);
  //           }
  //           let _name = await _contract.name();
  //           return { _name, _token };
  //         })
  //       );
  //       // setSelectToken(tokens)
  //       // setSelectToken(tokens[0]._token);
  //       setOptions(tokens);
  //     }
  //     setloading(false);
  //   } catch (error) {
  //     console.log(error, "error");
  //     setloading(false);
  //   }
  // };
  React.useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const tokens = await _getAllTokens(
          contract,
          signer,
          ethers,
          VoidSigner,
          tokenABI
        );
        setOptions(tokens);
        setloading(false);
      } catch (error) {
        console.log(error, "error");
        setloading(false);
      }
    })();
  }, [account, contract]);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Box
            sx={{
              padding: [0, 2, 5],
              margin: [1, 0, 0],
            }}
          >
            <Box
              sx={{
                backgroundColor: "#34C1C3",
                borderRadius: "0.75em",
              }}
              width={{ xs: "98%", sm: "100%" }}
              p={1}
              pb={7}
            >
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                  textAlign: "center",
                }}
              >
                Select Token
              </Box>
              <CustomSelect onChange={optionChangeHandler} options={options}>
                {options.map((option, index) => (
                  <StyledOption value={option._token} key={index}>
                    {option._name ? option._name : "loading..."}
                  </StyledOption>
                ))}
              </CustomSelect>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} lg={6} md={12} sm={12}>
          <Box
            sx={{
              padding: [0, 1, 5],
              margin: [1, 0, 0],
            }}
          >
            <Box
              sx={{
                backgroundColor: "#34C1C3",
                borderRadius: "0.75em",
                padding: ".5rem .5rem",
              }}
              width={{ xs: "98%", sm: "100%" }}
            >
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                  textAlign: "center",
                }}
              >
                Add Token
              </Box>
              <input
                onChange={(e) => setTokenValue(e.target.value)}
                value={tokenValue}
                style={{
                  fontSize: "1.2rem",
                  width: "98%",
                  height: "3rem",
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              />
              <Box
                pt={2}
                sx={{
                  fontSize: "1.5rem",
                  // width: "98%",
                  // height: "3.5rem",
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              >
                Cost to Spin
              </Box>
              <input
                type="number"
                onChange={(e) => setTokenAmount(e.target.value)}
                value={tokenamount}
                style={{
                  fontSize: "1.2rem",
                  width: "98%",
                  height: "3rem",
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  pt={2}
                  sx={{
                    fontSize: "1.5rem",
                    // width: "98%",
                    // height: "3.5rem",
                    fontFamily: "Bebas Neue",
                    color: "#D5339A",
                  }}
                >
                  Name:{tokenName ? tokenName : ""}
                </Box>

                <Box>
                  <button onClick={_AddHandler}>Add</button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid> */}
      </Grid>
    </Container>
  );
};

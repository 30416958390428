import { Dialog } from "@mui/material";
import * as React from "react";

import Slide from "@mui/material/Slide";

import { Box, Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function AgeModal({ ageSetOpen, ageopen, _setAuthAge }) {
  const handleClose = () => {
    ageSetOpen(false);
    _setAuthAge(2);
  };

  return (
    <div>
      <Dialog
        open={ageopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            background:
              "linear-gradient(74deg, rgba(129,181,113,1) 0%, rgba(1,172,228,1) 100%)",
            boxShadow: "none",
            padding: "1rem",
            borderRadius: ".5rem",
          },
        }}
      >
        <Box
          fontSize={[20, 30, 50]}
          sx={{
            color: "#D5339A",
            fontFamily: "'Bebas Neue', cursive",
          }}
        >
          Are you 21 or over?
        </Box>
        <Box
          sx={{
            color: "red",
            fontFamily: "'Bebas Neue', cursive",
            textDecoration: "underline",
          }}
          fontSize={[15, 16, 20]}
        >
          requires a minimum age of 21 to enter.
        </Box>
        <Box>
          <Box>
            <Button
              fullWidth
              sx={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#D5339A",
                marginRight: "1rem",
                borderRadius: "5px",
                color: "#fff",
                fontSize: ["20px", "20px", "25px"],
                fontFamily: "'Bebas Neue', cursive",
                boxShadow:
                  "-5px 1px 0px 1px #D5339A, -2px 2px #D5339A, -2px 2px #D5339A",
                border: "1px solid #D5339A",
                textShadow: "black 1px 1px 2px",
                transition: "all 0.3s ease-in-out",
                outline: "none",
              }}
              onClick={() => {
                ageSetOpen(false);
                _setAuthAge(1);
              }}
            >
              YES
            </Button>
          </Box>

          <Box>
            <Button
              fullWidth
              sx={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#D5339A",
                marginRight: "1rem",
                borderRadius: "5px",
                color: "#fff",
                fontSize: ["20px", "20px", "25px"],
                fontFamily: "'Bebas Neue', cursive",
                boxShadow:
                  "-5px 1px 0px 1px #D5339A, -2px 2px #D5339A, -2px 2px #D5339A",
                border: "1px solid #D5339A",
                textShadow: "black 1px 1px 2px",
                transition: "all 0.3s ease-in-out",
                outline: "none",
              }}
              onClick={() => {
                ageSetOpen(false);
                _setAuthAge(2);
              }}
            >
              NO
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}

import React from "react";
import { AppContext } from "../../context/context";
import styled from "styled-components";
import { Box } from "@mui/material";
// import { WalletDialog } from "../../model";
const StyledContainer = styled.div`
  width: 100%;
  background-color: #1b0f23;
  border-bottom: 1px solid #1f1228;
`;
const StyledContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`;
export const Header = ({ isSetOpen, _authAge }) => {
  const {
    account,
    signer,
    connect,
    disconnect,
    connectToDefiWallet,

    walletName,
  } = React.useContext(AppContext);

  // console.log(account, walletName, "walletName");

  return (
    <Box>
      <StyledContainer className="container_header">
        <StyledContent>
          <div className="c_logo">
            <a
              href="https://cryptosyno.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="logo.png" alt="" style={{}} />
            </a>
          </div>
          <div>
            {+_authAge === 1 && (
              <button
                className="button"
                onClick={() => {
                  account ? disconnect() : isSetOpen(true);
                }}
              >
                {account
                  ? account.slice(0, 5) + "..." + account.slice(-5)
                  : "Connect to  Wallet"}
              </button>
            )}
          </div>
        </StyledContent>
        {+_authAge === 1 && (
          <div
            style={{
              color: "red",
              float: "right",
              paddingRight: "1rem",
            }}
          >
            Note: If your wallet is not connecting please refresh the page.
          </div>
        )}
      </StyledContainer>
    </Box>
  );
};

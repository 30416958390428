let Environment = {
  //testnet
  SpinContractAddress: "0x59B2C3d67F502dAD73021c02dA2486FB53c885C9",
  // tokenSpinAddress: "0x5E12223e4Dc39787684f1665C9Cc087C31093930",
  //mainnet
  // SpinContractAddress: "0x7CB0e31e7ff6b6D7102b90B8770FC62DEc18adaD",
  // tokenSpinAddress: "0x6d1a4dea5A380C6Bb763BDd3ED7fB77aF6d480B5",
  //viptoken main net;
  vipTokenAddress: "0xeB3262Dc084b7A8ebf41DE9EDd32B7686f9a3100",

  // testnet
  // vipTokenAddress: "0x0fEc7AE1599342A753d04056a60515654c651Bd4",

  //
};
export default Environment;
// https://testnet.bscscan.com/address/0x431023292200717912990F401ebAf65a8660AA37#writeContract

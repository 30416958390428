import icon1 from "./assets/iocns/ic1.gif";
import icon2 from "./assets/iocns/ic2.gif";
import icon3 from "./assets/iocns/ic3.gif";
import icon4 from "./assets/iocns/ic4.gif";
import icon5 from "./assets/iocns/ic5.gif";
import icon6 from "./assets/iocns/ic6.gif";
import icon7 from "./assets/iocns/ic7.gif";
import icon8 from "./assets/iocns/ic8.gif";
import icon9 from "./assets/iocns/ic9.gif";
import icon10 from "./assets/iocns/ic10.gif";
import icon11 from "./assets/iocns/ic11.gif";

export let fruitOne = [
  { name: "a", vagi: icon1 },
  { name: "b", vagi: icon2 },
  { name: "c", vagi: icon3 },
  { name: "d", vagi: icon4 },
];
export let fruitTwo = [
  { name: "p", vagi: icon5 },
  { name: "q", vagi: icon6 },
  { name: "r", vagi: icon7 },
];
export let fruitThree = [
  { name: "bb", vagi: icon8 },
  { name: "cc", vagi: icon9 },
  { name: "cc", vagi: icon10 },
  { name: "dd", vagi: icon11 },
];

export const _getWinners = async (contract) => {
  const totalWins = await contract.totalWins();
  const _array = Array.from(Array(+totalWins).keys());
  if (_array.length > 0) {
    const _wins = await Promise.all(
      _array.map(async (i) => {
        const getWinners = await contract.winers(i);
        const _players = await contract.Player(getWinners);

        return {
          wincount: _players.WinCount,
          player: getWinners,
        };
      })
    );

    let sortLarges = _wins.sort(function (a, b) {
      return +a.WinCount - +b.WinCount;
    });
    return sortLarges;
  }
};

export const _getAllTokens = async (
  contract,
  signer,
  ethers,
  VoidSigner,
  tokenABI
) => {
  try {
    // setloading(true);
    const length = await contract.length();
    const getTokensList = Array.from(Array(+length).keys());
    if (getTokensList.length) {
      const tokens = await Promise.all(
        getTokensList.map(async (i) => {
          let _token = await contract.tokens(i);
          let _contract;
          if (signer) {
            _contract = new ethers.Contract(_token, tokenABI, signer);
          } else {
            _contract = new ethers.Contract(_token, tokenABI, VoidSigner);
          }
          let _name = await _contract.name();
          return { _name, _token };
        })
      );
      return tokens;
      // setSelectToken(tokens)
      // setSelectToken(tokens[0]._token);
      // setOptions(tokens);
    }
    // setloading(false);
  } catch (error) {
    console.log(error, "error");
    // setloading(false);
  }
};

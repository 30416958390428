import { Dialog } from "@mui/material";
import * as React from "react";

import Slide from "@mui/material/Slide";

import { Box, Button } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function VipStatusModal({ SetOpen, open }) {
  const handleClose = () => {
    SetOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            background:
              "linear-gradient(74deg, rgba(129,181,113,1) 0%, rgba(1,172,228,1) 100%)",
            boxShadow: "none",
            padding: "1rem",
            borderRadius: ".5rem",
          },
        }}
      >
        <Box
          sx={{
            color: "red",
            fontFamily: "'Bebas Neue', cursive",
            textAlign: "right",
          }}
          fontSize={[30, 35, 30]}
          onClick={() => SetOpen(false)}
        >
          X
        </Box>
        <Box
          fontSize={[20, 30, 50]}
          sx={{
            color: "#D5339A",
            fontFamily: "'Bebas Neue', cursive",
          }}
        >
          VIP STATUS
        </Box>
        <Box
          sx={{
            color: "red",
            fontFamily: "'Bebas Neue', cursive",
            textDecoration: "underline",
          }}
          fontSize={[15, 16, 20]}
        >
          - Grants personalized remote concierge service.
        </Box>
        <Box
          sx={{
            color: "red",
            fontFamily: "'Bebas Neue', cursive",
            textDecoration: "underline",
          }}
          fontSize={[15, 16, 20]}
        >
          - Exclusive access to upcoming games.
        </Box>
        <Box
          sx={{
            color: "red",
            fontFamily: "'Bebas Neue', cursive",
            textDecoration: "underline",
          }}
          fontSize={[15, 16, 20]}
        >
          - Exclusive access to upcoming tokens.
        </Box>
      </Dialog>
    </div>
  );
}

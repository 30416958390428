import { Box } from "@mui/system";
import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import { CustomSelect, StyledOption } from "./selector";

const Info = ({ standardwin, jackpost, _wins, lose }) => {
  // const [bitwin, setBigWin] = useState(0);
  // const [stadardwin, setStanardWin] = useState(0);
  // const [houseEdge, setHouseEdge] = useState(0);

  // React.useEffect(() => {
  //   (async () => {
  //     if (contract) {
  //       const divider = await contract.percentDivider();

  //       setBigWin(((await contract.BigwinChance()) / +divider) * 100);
  //       setStanardWin(((await contract.StandardwinChance()) / +divider) * 100);
  //       setHouseEdge(((await contract.houseEdgewinChance()) / +divider) * 100);
  //     }
  //   })();
  // }, [contract]);

  return (
    <Container>
      <Box mt={2}>
        <Grid container align="center" spacing={2}>
          <Grid item xs={12} lg={6} md={6} sm={12}>
            <Box
              sx={{
                backgroundColor: "#34C1C3",
                padding: "1.5rem .5rem",
                borderRadius: "0.75em",
              }}
            >
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              >
                Standard Reward{" "}
              </Box>
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                }}
              >
                {standardwin ? parseFloat(standardwin).toFixed(2) : 0}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={12}>
            <Box
              sx={{
                backgroundColor: "#34C1C3",
                padding: "1.5rem .5rem",
                borderRadius: "0.75em",
              }}
            >
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              >
                Big Reward
              </Box>
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                }}
              >
                {jackpost ? parseFloat(jackpost).toFixed(2) : 0}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={12}>
            <Box
              sx={{
                backgroundColor: "#34C1C3",
                padding: "1.5rem .5rem",
                borderRadius: "0.75em",
              }}
            >
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              >
                YOU WON{" "}
              </Box>
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                }}
              >
                {_wins ? +_wins : 0}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={12}>
            <Box
              sx={{
                backgroundColor: "#34C1C3",
                padding: "1.5rem .5rem",
                borderRadius: "0.75em",
              }}
            >
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              >
                YOU LOST
              </Box>
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                }}
              >
                {+lose ? +lose : 0}
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12} lg={4} md={6} sm={12}>
            <Box
              sx={{
                backgroundColor: "#34C1C3",
                padding: "1rem .5rem",
                borderRadius: "0.75em",
              }}
            >
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "#D5339A",
                }}
              >
                odds of winning
              </Box>
              <Box
                sx={{
                  fontSize: [20, 25, 30],
                  fontFamily: "Bebas Neue",
                  color: "black",
                  display: "flex",
                  // padding: "1rem .5rem",
                  // borderRadius: "0.75em",
                }}
              >
                <CustomSelect
                //  onChange={optionChangeHandler} options={options}
                >
                  <StyledOption value={houseEdge ? +houseEdge : 0}>
                    HouseEdge {houseEdge ? +houseEdge : 0}%
                  </StyledOption>
                  <StyledOption value={bitwin ? +bitwin - +houseEdge : 0}>
                    {" "}
                    Big Win {bitwin ? +bitwin - +houseEdge : 0}%
                  </StyledOption>
                  <StyledOption value={stadardwin ? +stadardwin - +bitwin : 0}>
                    {" "}
                    StandsardWin {stadardwin ? +stadardwin - +bitwin : 0}%
                  </StyledOption>
                </CustomSelect>
              </Box>
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </Container>
  );
};

export default Info;

import React from "react";
import { fruitOne, fruitThree, fruitTwo } from "../../utils";

import icon1 from "../../assets/iocns/ic1.gif";
import icon2 from "../../assets/iocns/ic2.gif";
import icon3 from "../../assets/iocns/ic3.gif";
import icon4 from "../../assets/iocns/ic4.gif";
import icon5 from "../../assets/iocns/ic5.gif";
import icon6 from "../../assets/iocns/ic6.gif";
import icon7 from "../../assets/iocns/ic7.gif";
import icon8 from "../../assets/iocns/ic8.gif";
import icon9 from "../../assets/iocns/ic9.gif";
import icon10 from "../../assets/iocns/ic10.gif";
import icon11 from "../../assets/iocns/ic11.gif";
import { Box } from "@mui/system";
import { Button, ButtonGroup } from "@mui/material";

const fruits = [
  { name: "a", vagi: icon1 },
  { name: "b", vagi: icon2 },
  { name: "c", vagi: icon3 },
  { name: "d", vagi: icon4 },
  { name: "e", vagi: icon5 },
  { name: "f", vagi: icon6 },
  { name: "g", vagi: icon7 },
  { name: "h", vagi: icon8 },
  { name: "i", vagi: icon9 },
  { name: "i", vagi: icon10 },
  { name: "i", vagi: icon11 },
  // { name: "j", vagi: "🥠" },
  // { name: "k", vagi: "🍏" },
  // { name: "l", vagi: "🧅" },
  // { name: "m", vagi: "🥑" },
  // { name: "n", vagi: "🥒" },
  // { name: "o", vagi: "🥔" },
  // { name: "p", vagi: "🧄" },
  // { name: "q", vagi: "🍄" },
  // { name: "r", vagi: "🌶" },
  // { name: "s", vagi: "🥝" },
  // { name: "t", vagi: "🥥" },
  // { name: "u", vagi: "🍗" },
  // { name: "v", vagi: "🍖" },
  // { name: "w", vagi: "🏺" },
  // { name: "x", vagi: "🍐" },
  // { name: "y", vagi: "🥕" },
  // { name: "z", vagi: "🧅" },
  // { name: "aa", vagi: "🥜" },
  // { name: "bb", vagi: "🌹" },
  // { name: "cc", vagi: "🍂" },
  // { name: "cc", vagi: "🍁" },
  // { name: "dd", vagi: "🥧" },
  // { name: "dd", vagi: "🍔" },
  // { name: "ee", vagi: "🍩" },
  // { name: "ff", vagi: "🍨" },
  // { name: "gg", vagi: "🍕" },
  // { name: "hh", vagi: "🥞" },
  // { name: "ii", vagi: "🍦" },
  // { name: "jj", vagi: "🍬" },
  // { name: "kk", vagi: "🍭" },
  // { name: "ll", vagi: "🧁" },
  // { name: "mm", vagi: "🍛" },
  // { name: "nn", vagi: "🍃" },
  // { name: "oo", vagi: "🌳" },
  // { name: "pp", vagi: "🌴" },
  // { name: "qq", vagi: "🌵" },
  // { name: "rr", vagi: "🌾" },
  // { name: "ss", vagi: "🌿" },
  // { name: "tt", vagi: "🍀" },
  // { name: "uu", vagi: "🍁" },
  // { name: "vv", vagi: "🍂" },
  // { name: "ww", vagi: "🌲" },
  // { name: "xx", vagi: "🌱" },
  // { name: "yy", vagi: "☘" },
  // { name: "zz", vagi: "🥀" },
  // { name: "aaa", vagi: "🌷" },
  // { name: "bbb", vagi: "🌼" },
  // { name: "ccc", vagi: "🌻" },
  // { name: "ddd", vagi: "🌺" },
];

// functions
function getRandom(arr) {
  let item = arr[Math.floor(Math.random() * arr.length)];
  return item;
}

// Alarm component
class Alarm extends React.Component {
  render() {
    return (
      <div className="Alarm-container">
        <div className={`Alarm ${this.props.hasWon ? "alert" : ""}`}>
          <div className="Alarm-bulb" />
        </div>
      </div>
    );
  }
}

// Lights component
class Lights extends React.Component {
  static defaultProps = {
    numBulbs: 9,
  };
  render() {
    const bulbs = Array.from({ length: this.props.numBulbs }).map(
      (bulb, index) => <div key={index} className="Light-bulb"></div>
    );
    return <div className="Lights">{bulbs}</div>;
  }
}

class Reel extends React.Component {
  render() {
    let _One = getRandom(fruits);
    let _Two = getRandom(fruits);
    let _Three = getRandom(fruits);
    // let _One = "a";
    // let _Two = "a";
    // let _Three = "a";
    // console.log(getRandom(fruits), "getRandom(fruits)");
    // console.log(_One.name, _Two.name, _Three.name, "fruits");
    if (_One.name === _Two.name && _Two.name === _Three.name) {
      this.props.setMatchedCondition(true);
    }

    return (
      <div key={this.props.index} className="Reel">
        <div
          className={`Reel-plate Reel-plate-${this.props.index} ${
            this.props.playing ? "spin" : ""
          }`}
        >
          <span>
            {this.props.playing ? (
              this.props.matchedCondition ? (
                <img
                  src={this.props.fruit}
                  style={{
                    width: "100%",
                  }}
                  alt=""
                />
              ) : (
                <img
                  src={_One.vagi}
                  style={{
                    width: "100%",
                  }}
                  alt=""
                />
              )
            ) : this.props._backend ? (
              <img
                src={this.props._winFruite}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            ) : this.props.matchedCondition ? (
              <img
                src={this.props.fruit}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            ) : (
              <img
                src={_One.vagi}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            )}
          </span>
          <span>
            {this.props.playing ? (
              this.props.matchedCondition ? (
                <img
                  src={this.props.fruit}
                  style={{
                    width: "100%",
                  }}
                  alt=""
                />
              ) : (
                <img
                  src={_Two.vagi}
                  style={{
                    width: "100%",
                  }}
                  alt=""
                />
              )
            ) : this.props._backend ? (
              <img
                src={this.props._winFruite}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            ) : this.props.matchedCondition ? (
              <img
                src={this.props.fruit}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            ) : (
              <img
                src={_Two.vagi}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            )}
          </span>
          <span>
            {this.props.playing ? (
              this.props.matchedCondition ? (
                <img
                  src={this.props.fruit}
                  style={{
                    width: "90%",
                  }}
                  alt=""
                />
              ) : (
                <img
                  src={_Three.vagi}
                  style={{
                    width: "90%",
                  }}
                  alt=""
                />
              )
            ) : this.props._backend ? (
              <img
                src={this.props._winFruite}
                style={{
                  width: "90%",
                }}
                alt=""
              />
            ) : this.props.matchedCondition ? (
              <img
                src={this.props.fruit}
                style={{
                  width: "90%",
                }}
                alt=""
              />
            ) : (
              <img
                src={_Three.vagi}
                style={{
                  width: "90%",
                }}
                alt=""
              />
            )}
          </span>
        </div>
      </div>
    );
  }
}

// Slot Component
class Slot extends React.Component {
  render() {
    return (
      <div className="Slot">
        <div className="Slot-inner" />
      </div>
    );
  }
}

export class Machine extends React.Component {
  state = {
    reels: [
      { fruit: getRandom(fruitOne).vagi },
      { fruit: getRandom(fruitTwo).vagi },
      { fruit: getRandom(fruitThree).vagi },
    ],
    message: "Try your luck and win some mad prizes!",

    // playing: false,
    // hasWon: false,
  };

  render() {
    const reels = this.state.reels.map((reel, index) => (
      <Reel
        key={index}
        fruit={reel.fruit}
        index={index + 1}
        playing={this.props.playing}
        _winFruite={this.props.winFruite}
        _backend={this.props._backend}
        setMatchedCondition={this.props.setMatchedCondition}
        matchedCondition={this.props.matchedCondition}
      />
    ));

    return (
      <>
        <div className="Machine">
          <Alarm hasWon={this.state.hasWon} />
          <Lights />
          <div className="Reels">{reels}</div>

          <Box mt={0.5}>
            {" "}
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#D5339A",
                }}
                onClick={this.props.handleDecrement}
              >
                -
              </Button>
              {
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "none",
                    boxShadow: "none",
                  }}
                >
                  {this.props.counter}
                </Button>
              }

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#D5339A",
                  padding: ".2rem",
                }}
                onClick={this.props.handleIncrement}
              >
                +
              </Button>
            </ButtonGroup>
          </Box>
          <button
            onClick={this.props.handleClick}
            disabled={this.props.playing}
          >
            Play
          </button>
          <Slot />
          <div className="Machine-bottom">{/* */}</div>
          {/* <Message message={this.state.message} playing={this.state.playing} /> */}
        </div>
      </>
    );
  }
}

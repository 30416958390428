import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Container } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f7edb0",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function WinnerTable({ _winersPeople }) {
  const [winnersPeople, setWinnersPeople] = React.useState([]);

  React.useEffect(() => {
    if (_winersPeople.length > 0) {
      const ids = _winersPeople.map((o) => o.player);
      const filtered = _winersPeople.filter(
        ({ player }, index) => !ids.includes(player, index + 1)
      );
      setWinnersPeople(filtered);
    }
  }, [_winersPeople]);

  return (
    <Container maxWidth="md" sx={{ marginTop: "200px" }}>
      <TableContainer
        component={Paper}
        sx={{
          height: _winersPeople && _winersPeople.length > 5 ? "60vh" : "30vh",
        }}
      >
        <Table aria-label="customized table">
          <TableHead
            sx={{
              position: "sticky",
              top: "0",
            }}
          >
            <TableRow>
              <StyledTableCell style={{ backgroundColor: "#d7329a" }}>
                ADDRESS
              </StyledTableCell>
              <StyledTableCell
                style={{ backgroundColor: "#d7329a" }}
                align="right"
              >
                WON
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {winnersPeople.length > 0
              ? winnersPeople.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      sx={{
                        color: "#000000",
                      }}
                    >
                      {row.player}
                    </StyledTableCell>
                    <StyledTableCell
                      align="right"
                      sx={{
                        color: "#000000",
                      }}
                    >
                      {" "}
                      {+row.wincount}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              : "not Found!"}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

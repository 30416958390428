import { Dialog } from "@mui/material";
import * as React from "react";
import { useMediaQuery } from "@mui/material";
import Slide from "@mui/material/Slide";
import defi from "../assets/img/defi.png";
import metamask from "../assets/img/metamask.png";
import trustWallet from "../assets/img/trust.png";
import { Box, Button } from "@mui/material";
import { AppContext } from "../context/context";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function WalletDialog({ isSetOpen, isopen }) {
  const [_nftbool, _setNftBool] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const matches = useMediaQuery("(max-width:550px)");
  const {
    account,
    signer,
    connect,
    disconnect,
    connectToDefiWallet,

    walletName,
  } = React.useContext(AppContext);

  const handleClose = () => {
    isSetOpen(false);
  };

  return (
    <div>
      <Dialog
        open={isopen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            background:
              "linear-gradient(74deg, rgba(129,181,113,1) 0%, rgba(1,172,228,1) 100%)",
            boxShadow: "none",
            padding: "1rem",
            borderRadius: ".5rem",
            // position: "relative",
          },
        }}
      >
        <Box
          sx={
            {
              // display: "flex",
              // justifyContent: "flex-end",
            }
          }
        >
          <Box>
            <Button
              fullWidth
              sx={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#D5339A",
                marginRight: "1rem",
                borderRadius: "5px",
                color: "#fff",
                fontSize: ["20px", "20px", "25px"],
                fontFamily: "'Bebas Neue', cursive",
                boxShadow:
                  "-5px 1px 0px 1px #D5339A, -2px 2px #D5339A, -2px 2px #D5339A",
                border: "1px solid #D5339A",
                textShadow: "black 1px 1px 2px",
                transition: "all 0.3s ease-in-out",
                outline: "none",
              }}
              onClick={() => {
                account ? disconnect() : connect();
                isSetOpen(false);
              }}
            >
              <img
                src={matches ? trustWallet : metamask}
                alt="metamask"
                style={{
                  width: "8%",
                }}
              />{" "}
              &nbsp;
              {walletName === "walletConnect"
                ? account
                  ? account.slice(0, 5) + "..." + account.slice(-5)
                  : " Connect to Wallet"
                : " Connect to WalletConnect"}
            </Button>
          </Box>

          <Box>
            <Button
              fullWidth
              sx={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#D5339A",
                marginRight: "1rem",
                borderRadius: "5px",
                color: "#fff",
                fontSize: ["20px", "20px", "25px"],
                fontFamily: "'Bebas Neue', cursive",
                boxShadow:
                  "-5px 1px 0px 1px #D5339A, -2px 2px #D5339A, -2px 2px #D5339A",
                border: "1px solid #D5339A",
                textShadow: "black 1px 1px 2px",
                transition: "all 0.3s ease-in-out",
                outline: "none",
              }}
              onClick={() => {
                account ? disconnect() : connectToDefiWallet();
                isSetOpen(false);
              }}
            >
              <img
                src={defi}
                alt="defi"
                style={{
                  width: "8%",
                }}
              />
              {walletName === "DeFiWallet"
                ? account
                  ? account.slice(0, 5) + "..." + account.slice(-5)
                  : "Connect to Defi Wallet"
                : "Connect to DeFi Wallet"}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
